<template>
  <div>
    <transition name="fade">
      <div
        @click="menuClose"
        v-show="show"
        class="xl:hidden transition-all duration-500 fixed z-full bg-black bg-opacity-30 w-screen h-screen top-0 left-0"
      ></div>
    </transition>
    <div
      :class="show ? 'left-0' : '-left-full'"
      class="w-64 xl:w-72 transition-all duration-500 ease-linear 2xl:w-80 h-screen z-full bg-111E52 fixed xl:left-0 top-0 text-white py-4 px-3 xl:py-8 xl:px-6"
    >
      <div class="overflow-y-auto h-full">
        <div class="text-3xl xl:text-4xl font-medium flex items-center px-3">
          SYSTEM
        </div>
        <div class="mt-10 xl:mt-16">
          <!-- <font-awesome-icon icon="fa-solid fa-user-secret" /> -->
          <div class="-my-1">
            <div class="py-1">
              <router-link
                to="/"
                class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                exact-active-class="bg-white text-111E52"
              >
                <icon icon="dashboard" class="mr-2"></icon> Dashboard
              </router-link>
            </div>
            <div class="py-1">
              <router-link
                to="/trains"
                class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                active-class="bg-white text-111E52"
              >
                <icon icon="trains" class="mr-2"></icon> {{ $t("trains") }}
              </router-link>
            </div>
            <div class="py-1">
              <router-link
                to="/inspections"
                class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                active-class="bg-white text-111E52"
              >
                <icon icon="inspection" class="mr-2"></icon>
                {{ $t("inspections") }}
              </router-link>
            </div>
            <div class="py-1" v-if="role == 99 || role == 100">
              <router-link
                to="/samples"
                class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                active-class="bg-white text-111E52"
              >
                <icon icon="dashboard" class="mr-2"></icon> {{ $t("sample") }}
              </router-link>
            </div>
            <div class="py-1" v-if="role != 9">
              <router-link
                to="/spares"
                class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                active-class="bg-white text-111E52"
              >
                <icon icon="spares" class="mr-2"></icon> {{ $t("spares") }}
              </router-link>
            </div>
            <div class="py-1" v-if="role == 99 || role == 100">
              <button
                class="flex w-full items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                @click="openDropdown = !openDropdown"
              >
                <icon icon="settings" class="mr-2"></icon> {{ $t("settings") }}
              </button>
              <transition name="fade">
                <div
                  class="ml-4 mt-2 overflow-hidden bg-white bg-opacity-5 rounded"
                  v-show="openDropdown"
                >
                  <div class="py-1">
                    <router-link
                      to="/carriage_types"
                      class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                      exact-active-class="bg-white text-111E52"
                    >
                      • {{ $t("carriage_types") }}
                    </router-link>
                  </div>
                  <div class="py-1">
                    <router-link
                      to="/equipment_type"
                      class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                      active-class="bg-white text-111E52"
                    >
                      • {{ $t("equipment_type") }}
                    </router-link>
                  </div>
                  <div class="py-1">
                    <router-link
                      to="/places"
                      class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                      active-class="bg-white text-111E52"
                    >
                      • {{ $t("places") }}
                    </router-link>
                  </div>
                  <div class="py-1">
                    <router-link
                      to="/instructions"
                      class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                      active-class="bg-white text-111E52"
                    >
                      • {{ $t("instructions") }}
                    </router-link>
                  </div>
                  <div class="py-1">
                    <router-link
                      to="/tasks"
                      class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                      active-class="bg-white text-111E52"
                    >
                      • {{ $t("tasks") }}
                    </router-link>
                  </div>
                  <div class="py-1">
                    <router-link
                      to="/corrective_type"
                      class="flex items-center text-lg leading-6 font-medium py-3 px-4 rounded"
                      active-class="bg-white text-111E52"
                    >
                      • {{ $t("corrective_type") }}
                    </router-link>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon from "./icon.vue";
export default {
  components: { icon },
  name: "Navigation",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    openDropdown: false,
    role: localStorage.role,
  }),
  methods: {
    menuClose() {
      this.$emit("menuClose");
    },
  },
  watch: {
    show() {
      document.body.classList.toggle("overflow-hidden");
    },
  },
};
</script>

<style lang="scss" scoped></style>
