<template>
  <div
    class="h-20 left-0 xl:pl-72 2xl:pl-80 bg-white shadow-sm border-b w-full fixed top-0 z-30"
  >
    <div class="flex justify-between items-center px-6 h-full">
      <button @click="openMenu" class="xl:hidden pr-4">
        <icon icon="menu"></icon>
      </button>
      <el-button v-if="back" @click="goBack"
        ><i class="el-icon-back"></i> Orqaga</el-button
      >
      <div class="flex-1"></div>
      <el-dropdown>
        <span class="el-dropdown-link flex items-center">
          <span class="mr-2 text-lg font-medium">{{username}}</span>
          <el-avatar><i class="el-icon-user"></i></el-avatar>
        </span>
        <el-dropdown-menu slot="dropdown">
          <div class="mx-2">
            <button
              @click="locale = 'uz'"
              :class="{ 'bg-111E52 text-white': locale === 'uz' }"
              class="mb-2 px-3 rounded border border-111E52 flex w-full"
            >
              O'zbekcha
            </button>
            <button
              @click="locale = 'ru'"
              :class="{ 'bg-111E52 text-white': locale === 'ru' }"
              class="mb-2 px-3 rounded border border-111E52 flex w-full"
            >
              Русский
            </button>
            <button
              class="px-3 rounded border border-111E52 flex items-center justify-between w-full"
              @click="logout"
            >
              {{ $t("logout") }} <i class="el-icon-right"></i>
            </button>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import icon from "./icon.vue";
export default {
  components: { icon },
  name: "Header",
  data() {
    return {
      back: false,
      locale: "ru",
      username: ""
    };
  },
  created() {
    this.$route.params.id ? (this.back = true) : (this.back = false);
    this.$i18n.locale = localStorage.lang ? localStorage.lang : "ru";
    this.locale = localStorage.lang ? localStorage.lang : "ru";
  },
  mounted(){
    this.username = localStorage.getItem('username')
  },
  methods: {
    openMenu() {
      this.$emit("openMenu");
    },
    logout() {
      this.$router.push("/login");
      localStorage.clear();
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    immediate: true,
    $route() {
      this.$route.params.id ? (this.back = true) : (this.back = false);
    },
    locale(newVal) {
      this.$i18n.locale = newVal;
      localStorage.setItem("lang", newVal);
    },
  },
};
</script>

<style lang="scss" scoped></style>
