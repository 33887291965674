<template>
  <div class="min-h-screen">
    <navigation :show="showMenu" @menuClose="menuClose"/>
    <div class="xl:pl-72 2xl:pl-80 pt-20 min-h-screen">
      <Header @openMenu="openMenu"/>
      <router-view class="pb-20 px-3 py-4 lg:py-6 lg:px-4 xl:py-8 xl:px-6 bg-gray-100 min-h-screen"></router-view>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import navigation from "../components/navigation.vue";
export default {
  components: { navigation, Header },
  name: "default",
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    openMenu(){
      this.showMenu = true
    },
    menuClose(){
      this.showMenu = false
    }
  },
  watch: {
    "$route"(){
      this.showMenu = false
    }
  }
};
</script>

<style lang="scss" scoped></style>
